import { styled } from '@magal/styles'
import { Close } from '@magal/icons'
import { FC } from 'react'

const Root = styled('section', {
  position: 'absolute',
  minWidth: '100%',
})

const Modal = styled('div', {
  border: '1px solid #000',
  position: 'absolute',
  zIndex: 10,
  padding: '$12 $8',
  background: '$gray300',
  maxWidth: '280px',
  marginTop: '-150px',
  '@lg': {
    marginTop: 0,
    marginLeft: '-100px',
  },
  variants: {
    open: {
      true: {
        display: 'inline-block',
      },
    },
  },
})

const LetterPicker = styled('button', {
  padding: '$8',
  background: '$white',
  display: 'inline-block',
  projectFont: 'caps03',
  border: '1px solid #000',
  margin: '$4',
  width: '35px',
  color: '$black',
})

const ModalWrapper = styled('div', {
  marginBottom: 10,
})

const CloseWrapper = styled('button', {
  width: '100%',
  display: 'inline-block',
  textAlign: 'center',
  background: 'none',
  border: '2px solid black',
  color: '$black',
})

const LETTERS = [...'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890']

interface Props {
  onPick: (picked: string) => void
  onDismiss: () => void
}

export const LetterPopup: FC<Props> = ({ onPick, onDismiss }) => {
  const handleClick = (picked: string): any => {
    onPick(picked)

    return
  }

  return (
    <Root>
      <Modal>
        <ModalWrapper>
          {LETTERS.map((l: string) => (
            <LetterPicker onClick={() => handleClick(l)}>{l}</LetterPicker>
          ))}
        </ModalWrapper>

        <CloseWrapper onClick={onDismiss}>
          <Close />
        </CloseWrapper>
      </Modal>
    </Root>
  )
}
