import { styled } from '@magal/styles'
import { LogoLoading } from '@magal/icons'
import { FC, useEffect, useState } from 'react'
import { getShopifyProductExtended } from '@magal/services/shopify-service'
import {
  SectionProductConversionAreaProps,
  AdvancedProductOption,
} from '@magal/models'
import { useRouter } from 'next/router'
import { captureException } from '@sentry/nextjs'
import { PriceRange } from '@magal/components'
import { LetterPopup } from './LetterPopup'

const Root = styled('section', {
  backgroundColor: '$white',
  borderRadius: '$r2',
  minWidth: '100%',
  padding: '30px 15px',
  position: 'relative',
  '@lg': {
    backgroundColor: '$gray300',
    marginTop: '0',
    padding: '10px 30px 25px',
  },
})

const Picture = styled('picture', {
  width: '80px',
  maxWidthidth: '98%',
  height: '80px',
  display: 'inline-block',
  border: '1px solid black',
  overflow: 'hidden',
  marginBottom: 10,
  '& img': {
    backgroundColor: '$gray300',
    height: '100%',
    objectFit: 'cover',
    width: '100%',
    transform: 'scale(2)',
  },
  variants: {
    active: {
      true: {
        borderColor: '$red300',
        borderWidth: '2px',
      },
    },
  },
})

const CharmImg = styled('img', {
  backgroundColor: '$gray300',
  height: '100%',
  objectFit: 'cover',
  width: '100%',
  transform: 'scale(1.5)',
})

const CharmsList = styled('div', {
  display: 'flex',
  margin: '0 -40px',
  padding: '0 40px 10px',
  overflowX: 'scroll',
  '@lg': {
    padding: 0,
    margin: -10,
    flexWrap: 'wrap',
    overflowX: 'visible',
  },
})

const Charms = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  margin: '5px 12px 0 0',
  cursor: 'pointer',
  alignItems: 'center',
  maxWidth: '33%',
  width: '100%',
  '@xxl': {
    maxWidth: '25%',
    margin: '5px 0',
  },
})

const CharmTitle = styled('p', {
  projectFont: 'body04',
  textAlign: 'center',
})

const CharmPrice = styled('p', {
  projectFont: 'body05',
  textAlign: 'center',
  color: '$red300',
  fontWeight: 'medium',
})

const CharmLetter = styled('p', {
  projectFont: 'body05',
  textAlign: 'center',
})

const LoadingWrapper = styled('div', {
  maxWidth: '100px',
  margin: '0 auto',
})

const CharmCount = styled('span', {
  projectFont: 'body05',
  display: 'inline-block',
  width: '80vw',
  textAlign: 'right',
  position: 'absolute',
  marginTop: -25,
  '@lg': {
    position: 'relative',
    marginBottom: 15,
    width: '100%',
  },
})

const useCharmData = (
  charms: SectionProductConversionAreaProps['charms'],
  locale: string | undefined,
) => {
  const [products, setProducts] = useState<any[]>([])

  useEffect(() => {
    if (!charms) return
    const getCharms = async () => {
      const tempCharms = []

      for (let p = 0; p < charms?.length; p++) {
        const item = charms[p]

        if (!item || !item?.handle) return false

        const theItem = await getShopifyProductExtended(
          item?.handle,
          locale ?? 'en',
        )

        if (theItem.status === 'ERROR') {
          const exception = new Error(
            `[[...paths]] Sanity getSiteConfiguration function error ${JSON.stringify(
              theItem.errors,
            )}`,
          )
          captureException(exception)
          throw exception
        }

        tempCharms.push(theItem.data)
      }

      return setProducts(tempCharms)
    }

    getCharms()
  }, [charms])

  return {
    products,
  }
}

interface CharmsListProps {
  featuredImage: any
  variants: any[]
  title: string
  priceRange: any
  id: string
  handle: string
  letter?: string
  handleCharms: (id: string, handle: string, priceRange: any) => void
  checkCharm: (handle: string) => boolean
}

const CharmProduct: FC<CharmsListProps> = ({
  variants,
  featuredImage,
  title,
  priceRange,
  id,
  handleCharms,
  checkCharm,
  handle,
  letter,
}) => {
  return (
    <Charms
      key={id}
      onClick={() => handleCharms(variants[0].id, handle, priceRange)}
    >
      <Picture active={checkCharm(handle)}>
        <CharmImg
          src={featuredImage?.image?.src}
          alt={featuredImage?.image?.alt || ''}
        />
      </Picture>
      <CharmTitle>{title}</CharmTitle>
      <CharmPrice>
        <PriceRange range={priceRange} shouldComparedPrice={true} />
      </CharmPrice>
      {!!letter && <CharmLetter>({letter})</CharmLetter>}
    </Charms>
  )
}

interface Props {
  charms: {
    handle: string
    letterPopUp: boolean
  }[]
  onChange: (optionId: string, valueId?: string, attrs?: any) => void
  onSelect: (activeCharms: any[]) => void
  option: AdvancedProductOption
}

export const ProductCharms: FC<Props> = ({
  charms,
  onSelect,
  onChange,
  option,
}) => {
  const { locale } = useRouter()
  const { products } = useCharmData(charms || [], locale)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [activeCharms, setActiveCharms] = useState<
    { id: string; price: any; handle: string; letter?: string }[]
  >([])

  useEffect(() => {
    onSelect(activeCharms)

    if (activeCharms.length > 0) {
      onChange(option.title, activeCharms.length.toString())
    }
  }, [activeCharms])

  useEffect(() => {
    if (products.length > 0) {
      setIsLoading(false)
    }
  }, [products])

  const checkCharm = (handle: string): boolean => {
    return activeCharms.some((charms) => charms.handle === handle)
  }

  const getLetter = (handle: string): string => {
    const theCharm = activeCharms.find(
      (charms) => charms.handle === handle,
    )?.letter

    return theCharm || ''
  }

  const hasPopUp = (handle: string): boolean => {
    return (
      charms.some((charm) => charm.handle === handle && !!charm.letterPopUp) &&
      activeCharms.some((ac) => ac.handle === handle) &&
      noLetterPicked(handle)
    )
  }

  const handleCharms = (id: string, handle: string, priceRange: any) => {
    if (checkCharm(handle)) {
      const tempList = activeCharms.filter((charm) => charm.handle !== handle)
      setActiveCharms(tempList)

      return
    }

    if (activeCharms.length >= (option.max_charms || 5)) return

    setActiveCharms([
      { id, handle, price: priceRange.maxVariantPrice.amount },
      ...activeCharms,
    ])

    return
  }

  const noLetterPicked = (handle: string) => {
    return activeCharms.some(
      (charm) => charm.handle === handle && charm.letter == null,
    )
  }

  const handleLetterPick = (handle: string, letter: string) => {
    const addLetter = activeCharms.map((ac) => {
      if (ac.handle === handle) {
        ac.letter = letter
      }

      return ac
    })

    setActiveCharms(addLetter)
  }

  if (!products) return null

  return (
    <Root>
      {!isLoading ? (
        <>
          <CharmCount>
            {activeCharms.length}/{option.max_charms}
          </CharmCount>
          <CharmsList>
            {products.map(
              ({ variants, featuredImage, title, priceRange, id, handle }) => (
                <>
                  <CharmProduct
                    variants={variants}
                    id={id}
                    handle={handle}
                    title={title}
                    priceRange={priceRange}
                    featuredImage={featuredImage}
                    handleCharms={
                      hasPopUp(handle) && noLetterPicked(handle)
                        ? (): any => null
                        : handleCharms
                    }
                    checkCharm={checkCharm}
                    letter={getLetter(handle)}
                  />
                  {hasPopUp(handle) && (
                    <LetterPopup
                      onDismiss={() => handleCharms(id, handle, priceRange)}
                      onPick={(letter: string) =>
                        handleLetterPick(handle, letter)
                      }
                    />
                  )}
                </>
              ),
            )}
          </CharmsList>
        </>
      ) : (
        <LoadingWrapper>
          <LogoLoading />
        </LoadingWrapper>
      )}
    </Root>
  )
}
